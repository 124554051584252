import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { accent } from '../constants/theme'

const WrapperBackground = styled.div`
  max-width: 800px;
  margin: 0 auto;
  position: relative;
`

const Divider = styled.div`
  border-bottom: 1px solid ${accent};
  max-width: 800px;
  margin: 1rem auto;
`

const H3 = styled.h3`
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  font-size: 1.5rem;
`

export const Faq = () => {
  return (
    <WrapperBackground>
      <h2 className='title is-size-2 has-text-info'>FAQ</h2>
      <Divider />
      <H3 className='has-text-primary'>
        What topics does the main course cover?
      </H3>
      <p>
        I cover the technical side of software for tech recruiters. I focus on
        explaining how engineers do their jobs, what software teams are like,
        common technology used, and of course, what engineers think about
        recruiters. You can{' '}
        <Link to='/courses'>
          view the entire curriculum here
        </Link>.
      </p>
      <p>
        What I don't cover is how to be a recruiter, or give advice about
        recruiting in general (other than to explain how engineers feel about
        recruiter messages).
      </p>
      <Divider />
      <H3 className='has-text-primary'>Is there a bulk discount available?</H3>
      <p>
        Absolutely! Just use the <Link to='/contact'>contact form</Link>, or
        email me directly (<a href='mailto:aaron@ard.ninja'>aaron@ard.ninja</a>)
        to talk.
      </p>
      <Divider />
      <H3 className='has-text-primary'>
        What other courses do you plan to make?
      </H3>
      <p>
        I have a course on just covering strategies on how to get developers to
        respond to messages in the works. I'm also working on bringing on more
        authors!
      </p>
      <Divider />
      <H3 className='has-text-primary'>What are your plans for the future?</H3>
      <p>
        I have another course in the works, and I'm building content for in
        person workshops. Long-term, I'm very interested in building software
        tools for recruiters (I do really like building products). If you want
        to collaborate on something get in touch!
      </p>
      <Divider />
      <H3 className='has-text-primary'>Will there be updates?</H3>
      <p>
        Yes! As I do more surveys, and get requests for more subjects I will be
        updating the course.
      </p>
      <Divider />
      <H3 className='has-text-primary'>Do you have an affiliates program?</H3>
      <p>
        Yes, <Link to='/contact'>contact me. </Link>
      </p>
      <Divider />
      <H3 className='has-text-primary'>What if I don&apos;t like it?</H3>
      <p>
        I offer a 30 day satisfaction guarantee on the course. I want you to be
        totally happy with this course! It hasn't happened yet, but if you're
        not satisfied I'll make it right.
      </p>
      <Divider />
      <H3 className='has-text-primary'>Have another question?</H3>
      <p>
        No problem, feel free to email me{' '}
        <a href='mailto:aaron@ard.ninja'>aaron@ard.ninja</a> or use the{' '}
        <Link to='/contact'>contact form.</Link> I try to answer all of my email
        promptly!
      </p>
      <Divider />
    </WrapperBackground>
  )
}
