import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'
import { Faq } from '../common/faq'
import { SubscribeForm } from '../common/subscribeForm'

const AboutPageTemplate = ({
  title,
  description,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content
  return (
    <div>
      <section className='hero is-primary is-bold'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title has-text-white is-size-1'>{title}</h1>
                  <h2 className='description has-text-white is-size-3'>
                    {description}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section section--gradient'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <Faq />
              <div className='section'>
                <PageContent className='content' content={content} />
              </div>
              <SubscribeForm />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default AboutPageTemplate
